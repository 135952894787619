.login-container {
  .login-content {
    background: url('https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lq-credit/login/login-bg.jpg') center;
    background-size: cover;
    height: calc(100vh - 70px);
    // opacity: 0.8;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      background: fade(#000, 35);
      display: block;
    }
    .form-container {
      width: 400px;
      height: 350px;
      background-color: #ffffff;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
      padding: 32px 48px;
      border-radius: 8px;
      position: fixed;
      top: calc((100vh - 350px) / 2);
      left: calc((100vw - 400px) / 2);
    }
    .form-row-wrapper {
      display: flex;
      justify-content: space-between;
      color: #646464;
    }
    .ant-input {
      font-size: 14px;
    }
  }

  .login-footer {
    height: 70px;
    background-color: #314659;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
  }
}

@primary-color: #1DA57A;