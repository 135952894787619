.product-list-page {
  .tools-wrapper {
    display: flex;
    justify-content: space-between;
    .product-list-filter-input-container {
      form {
        margin-bottom: 16px;
      }
      .filter-item {
        background-color: #ffffff;
        min-width: 290px;
        padding: 0 8px;
        .ant-input {
          border: none;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: none;
          box-shadow: none;
        }
        .ant-form-item-label > label {
          color: #2d3843;
          font-weight: bold;
        }
        .ant-btn {
          border-color: #ffffff;
          --antd-wave-shadow-color: "#ffffff";
        }
        .ant-picker {
          border: none;
          box-shadow: none;
        }
        .ant-input {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
.business-record-page {
  .tools-wrapper {
    display: flex;
    justify-content: space-between;
    .business-record-filter-input-container {
      form {
        margin-bottom: 16px;
      }
      .filter-item {
        background-color: #ffffff;
        min-width: 290px;
        padding: 0 8px;
        .ant-input {
          border: none;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: none;
          box-shadow: none;
        }
        .ant-form-item-label > label {
          color: #2d3843;
          font-weight: bold;
        }
        .ant-btn {
          border-color: #ffffff;
          --antd-wave-shadow-color: "#ffffff";
        }
        .ant-picker {
          border: none;
          box-shadow: none;
        }
        .ant-input {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

@primary-color: #1DA57A;