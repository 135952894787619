.userinfo-center {
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-form-item-label {
    text-align: start;
  }
  background: url(https://imetro-web-imgges.oss-cn-beijing.aliyuncs.com/client-images/userinfo_center_bg.png);
  height: 100vh;
  overflow-y: auto;
  .header {
    .header-title {
      color: #ffffff;
    }
  }
  .content {
    width: 1000px;
    border-radius: 5px;
    height: 700px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 50px 0;
    position: relative;
    .avatar {
      position: absolute;
      top: -50px;

      right: 138px;
    }
    .base-info {
      position: relative;
      .avatar-uploader {
        position: absolute;
        right: 138px;
        top: -50px;
      }
    }
  }
}

@primary-color: #1DA57A;