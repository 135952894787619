.reset-container {
  .reset-page-content {
    height: 100vh;
    display: flex;

    justify-content: center;
    align-items: center;
    background: url(https://imetro-web-imgges.oss-cn-beijing.aliyuncs.com/client-images/common_bg_test.png);
    .reset-wrapper {
      width: 600px;
      .reset-content {
        width: 100%;
        height: 500px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        .welcome {
          padding: 24px 0;
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #2d3843;
        }
      }
    }
  }
}

@primary-color: #1DA57A;